import { render, staticRenderFns } from "./comp_ProductCard-Design8.vue?vue&type=template&id=cf2d30dc&scoped=true&"
import script from "./comp_ProductCard-Design8.vue?vue&type=script&lang=js&"
export * from "./comp_ProductCard-Design8.vue?vue&type=script&lang=js&"
import style0 from "./comp_ProductCard-Design8.vue?vue&type=style&index=0&id=cf2d30dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf2d30dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VHover,VSpacer})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
